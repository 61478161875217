import React, { useEffect } from "react";
import {
  Form,
  Button,
  DatePicker,
  Checkbox,
  Modal,
  List,
  Typography,
  Space,
  Select,
  Tag,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import _ from "lodash";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { getTrainers } from "../../../../appRedux/actions";

export default function RescheduleTrainingForm({
  // currentDates = [],
  training = [],
  showWithNotificationField = true,
  onSubmit,
}) {
  const dispatch = useDispatch();
  const { trainers, getTrainersLoading } = useSelector(
    ({ trainer }) => trainer
  );

  useEffect(() => {
    dispatch(getTrainers());
  }, [dispatch]);

  let initDates = []
  training.dates.map((d) => {
    initDates.push({
      date: moment(d.date),
      trainer_id: d.trainer_id ? d.trainer_id : training.trainer_ids[0],
    })
  })

  return (
    <Form
      initialValues={{
        dates: initDates,
        with_notification: true,
      }}
      layout="vertical"
      onFinish={(v) => {
        // v.dates = v.dates.map((date) => date.endOf("day").toISOString());
        // v.dates = _.uniq(v.dates).sort();

        Modal.confirm({
          title: "Konfirmasi",
          content: (
            <List>
              {v.dates.sort().map((date, index) => {
                return (
                  <List.Item key={index}>
                    Hari ke-{index + 1} :{" "}
                    <br />
                    <Space>
                    <Typography.Text strong>
                      {moment(date.date).format("dddd, D MMMM yyyy")}
                    </Typography.Text>
                    <Tag>{trainers.find((o) => o.id === date.trainer_id).name}</Tag>
                    </Space>
                  </List.Item>
                );
              })}
            </List>
          ),
          onOk: () =>
            onSubmit({
              ...v,
              dates: v.dates,
              with_notification: showWithNotificationField
                ? v.with_notification
                : false,
            }),
        });
      }}
    >
      <Form.List
        name="dates"
        rules={[
          {
            validator: async (_, dates) => {
              if (!dates || dates.length < 1) {
                return Promise.reject(
                  new Error("Mohon tambahkan tanggal pelaksanaan training")
                );
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  key={field.key}
                  label={index === 0 ? "Tanggal pelaksanaan" : ""}
                >
                  <Space>
                    <Form.Item
                      // {...field}
                      name={[field.name, 'date']}
                      noStyle
                      // validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          message: "Mohon masukkan tanggal",
                        },
                      ]}
                    >
                      <DatePicker multiple format="dddd, D MMMM yyyy" />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, 'trainer_id']}
                      noStyle
                      rules={[
                        {
                          required: true,
                          message: "Mohon masukkan trainer",
                        },
                      ]}
                    // initialValue={defaultTrainerId}
                    >
                      <Select
                        showSearch
                        placeholder="Pilih trainer"
                        optionFilterProp="label"
                        style={{
                          width: "16em"
                        }}
                      >
                        {trainers.map((t) => (
                          <Select.Option label={t.name} key={t.id} value={t.id}>
                            {t.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Space>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      style={{ marginLeft: 10, cursor: "pointer" }}
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  icon={<PlusOutlined />}
                  onClick={() => add()}
                >
                  Tambah tanggal
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          );
        }}
      </Form.List>
      {showWithNotificationField && (
        <Form.Item name="with_notification" valuePropName="checked">
          <Checkbox>Kirim pemberitahuan ke seluruh peserta</Checkbox>
        </Form.Item>
      )}
      <Form.Item>
        <center>
          <Button size="large" type="primary" htmlType="submit">
            Simpan
          </Button>
        </center>
      </Form.Item>
    </Form>
  );
}
