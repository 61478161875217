import * as rax from "retry-axios";
import axios from "axios";
import axiosRetry from "axios-retry";

export const client = axios.create({
  // untuk sementara waktu pake npm start aja langsung, kayaknya ada masalah sama jaringanmu
  // kalau pake npm start, alamat backendnya harus diatur secara spesifik dibawah ini
  // aku kira di bikin .env mas kayak yg next js, sempet mikir gitu juga
  // harusnya iya, cuman projek frontend yg ini belum pake environment variable, alias masih manual
  baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost/api",
  headers: {
    "Content-Type": "application/json",
  },
  // timeout: 2000,
});
client.defaults.raxConfig = {
  instance: client,
};
// const interceptorId = rax.attach(client);
// axiosRetry(client);

// export default client;

export const baseRequest = (method, url, params, body) => {
  const token = localStorage.getItem("token");
  if (token) {
    client.defaults.headers.common["Authorization"] = "Bearer " + token;
  } else {
    delete client.defaults.headers.common["Authorization"];
  }

  return new Promise((resolve, reject) => {
    client({
      method,
      url,
      params,
      data: body,
    })
      .then((response) => {
        if (response.data) {
          resolve(response.data);
        } else {
          resolve(true);
        }
      })
      .catch((e) => {
        if (e.response) {
          if (e.response.data) {
            const { data } = e.response;
            if ("detail" in data) {
              const { detail } = data;
              if (Array.isArray(detail)) {
                const firstDetail = 0 in detail ? detail[0] : null
                if (firstDetail) {
                  const msg = "msg" in firstDetail? firstDetail["msg"] : null
                  if (msg)
                    reject(msg)
                }
                reject("mohon periksa kembali data yang anda masukkan");
              } else {
                reject(detail);
              }
            } else {
              reject(null);
            }
          } else {
            reject(null);
          }
        } else if (e.request) {
          reject("tidak ada respon dari server, mohon ulangi permintaan anda");
        } else {
          reject(e.message);
        }
      });
  });
};
