import React, { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { PageHeader, Card } from "antd";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "../../components/CircularProgress";
import moment from "moment";
import {
  getTraining,
  getTrainers,
  getTrainingOptions,
  updateTraining,
  rescheduleTraining,
} from "../../appRedux/actions";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
// import NewTrainingForm from "../../components/training/NewTrainingForm";
import RescheduleTrainingForm from "../../components/adminRole/training/RescheduleTrainingForm";

export default function RescheduleTrainingPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const { trainingId } = useParams();
  const { training, trainingLoading } = useSelector(({ training }) => training);
  useEffect(() => {
    dispatch(getTraining(trainingId));
  }, [dispatch]);
  const handleReschedule = (data) => {
    dispatch(
      rescheduleTraining({ ...data, training_id: training.id })
    ).then(() => history.push("/training/" + training.id));
  };
  return training && !trainingLoading ? (
    <>
      <PageHeader
        ghost={false}
        title={
          "Ganti Tanggal " +
          (!screens.xs
            ? training.training_option.name
            : training.training_option.name.split(" ")[0])
        }
        onBack={() => history.push("/training/" + training.id)}
      />
      <Card bordered={false} style={{ borderRadius: 0 }}>
        <RescheduleTrainingForm
          // currentDates={training.dates.map((t) => t.date)}
          showWithNotificationField={
            training.total_participants > 0 && training.is_running
          }
          training={training}
          onSubmit={handleReschedule}
        />
      </Card>
    </>
  ) : trainingLoading ? (
    <CircularProgress />
  ) : (
    "training tidak ditemukan"
  );
}
