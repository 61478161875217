// Import package
import { v4 } from "uuid";

// Import file
import {
  SET_PARTICIPANTS_LOADING,
  SET_PARTICIPANTS,
  SET_TOTAL_PARTICIPANTS,
  DELETE_PARTICIPANT,
  UPDATE_PARTICIPANT,
  SET_PARTICIPANT,
  SET_PARTICIPANT_LOADING,
} from "../../constants/ActionTypes";
import { baseRequest } from "../../util/Api";
import {
  setErrorMessage,
  setSuccessMessage,
  setLoadingMessage,
} from "./Common";

const errorMessage = "Data anda mengandung karakter yg tidak diperbolehkan, silahkan hubungi admin via whatsapp 0819-0819-1001"

const getErrorMessage = (e) => {
  if (e == "mohon periksa kembali data yang anda masukkan") {
    return errorMessage
  }
  return e
}

// Method-method
export const getParticipants = (filter) => (dispatch) => {
  dispatch({
    type: SET_PARTICIPANTS_LOADING,
    payload: true,
  });
  baseRequest("get", "/participants/", filter)
    .then(({ data, meta }) => {
      dispatch({
        type: SET_PARTICIPANTS,
        payload: data,
      });
      dispatch({
        type: SET_TOTAL_PARTICIPANTS,
        payload: meta.total,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_PARTICIPANTS_LOADING,
        payload: false,
      });
    });
};

export const getParticipant = (id) => (dispatch) =>
  new Promise((resolve) => {
    dispatch({
      type: SET_PARTICIPANT_LOADING,
      payload: true,
    });
    baseRequest("get", "/participants/" + id)
      .then((data) => {
        dispatch({
          type: SET_PARTICIPANT,
          payload: data,
        });
        resolve(data);
      })
      .catch((e) => {
        dispatch(setErrorMessage(e));
        dispatch({ type: SET_PARTICIPANT_LOADING, payload: false });
      });
  });

export const createParticipant = (data) => (dispatch) =>
  new Promise((resolve) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Menambah peserta ...", messageKey));
    baseRequest("post", "/participants/", null, data)
      .then((newData) => {
        dispatch(setSuccessMessage("Menambah peserta berhasil", messageKey));
        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(getErrorMessage(e), messageKey));
      });
  });

export const updateParticipant = (participant) => (dispatch) =>
  new Promise((resolve) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Memperbarui peserta ...", messageKey));
    baseRequest("put", "/participants/" + participant.id, null, participant)
      .then((newData) => {
        dispatch(setSuccessMessage("Memperbarui peserta berhasil", messageKey));
        dispatch({
          type: UPDATE_PARTICIPANT,
          payload: newData,
        });
        resolve(newData);
      })
      .catch((e) => {
        dispatch(setErrorMessage(getErrorMessage(e), messageKey));
      });
  });

export const editProfileParticipant = (participant) => (dispatch) =>
  new Promise((resolve) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Memperbarui profile ...", messageKey));
    baseRequest("put", "/participants/profile/", null, participant)
      .then((newData) => {
        dispatch(setSuccessMessage("Memperbarui profile berhasil", messageKey))
        dispatch({
          type: UPDATE_PARTICIPANT,
          payload: newData,
        })
        resolve(newData)
      })
      
      .catch((e) => {
        dispatch(setErrorMessage(getErrorMessage(e), messageKey));
      });
  });

export const deleteParticipant = (participantId) => (dispatch) =>
  new Promise((resolve, reject) => {
    const messageKey = v4();
    dispatch(setLoadingMessage("Menghapus peserta ...", messageKey));
    baseRequest("delete", "/participants/" + participantId)
      .then(() => {
        dispatch(setSuccessMessage("Menghapus peserta berhasil", messageKey));
        dispatch({
          type: DELETE_PARTICIPANT,
          payload: participantId,
        });
        resolve();
      })
      .catch((e) => {
        dispatch(setErrorMessage(e, messageKey));
        reject();
      });
  });
